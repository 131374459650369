/* eslint-disable no-unused-vars */
import DpFormMixin from 'src/core/components/DpForm/DpFormMixin.js'
import DpForm from 'src/core/components/DpForm/DpForm2.vue'
import VeiculoModel from 'src/model/veiculo/BemModel.js'
import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'
import Upload from 'src/components/Upload.vue'
import UploadMultiplo from 'src/components/UploadMultiplo.vue'
import BemTabs from './VeiculoTabs.vue'
import { clone, LocalStorage } from 'quasar'
import CidadeEstado from 'src/components/CidadeEstado/CidadeEstado'
import axios from 'axios'

// models
import LocalModel from 'src/model/usuario/LocalModel.js'
import CategoriaModel from 'src/model/veiculo/CategoriaModel'
import MarcaModel from 'src/model/veiculo/MarcaModel'
import ModeloModel from 'src/model/veiculo/ModeloModel'
import VersaoModel from 'src/model/veiculo/VersaoModel'
import CarroceriaModel from 'src/model/veiculo/CarroceriaModel'
import CorModel from 'src/model/veiculo/CorModel'
import CambioModel from 'src/model/veiculo/CambioModel'
import CombustivelModel from 'src/model/veiculo/CombustivelModel'
import TipoRetomadaModel from 'src/model/veiculo/TipoRetomadaModel'
import CaracteristicaModel from 'src/model/veiculo/CaracteristicaModel'
import AcessorioModel from 'src/model/veiculo/AcessorioModel'
import TagModel from 'src/model/veiculo/TagModel'
// precificador
import PrecificadorModel from 'src/model/veiculo/PrecificadorModel'
import DominioCredereModel from 'src/model/veiculo/DominioCredereModel'
import SituacaoAnuncioModel from 'src/model/veiculo/SituacaoAnuncioModel'
import MolicarModel from 'src/model/veiculo/MolicarModel'
import vTopFilters from 'src/core/filters/vTopFilters'
import IcarrosMarcaModel from 'src/model/veiculo/IcarrosMarcaModel'
import WebmotorsMarcaModel from 'src/model/veiculo/WebmotorsMarcaModel'
import UsadosbrMarcaModel from 'src/model/veiculo/UsadosbrMarcaModel'
import OlxMarcaModel from 'src/model/veiculo/OlxMarcaModel'
import MobiautoMarcaModel from 'src/model/veiculo/MobiautoMarcaModel'
import IcarrosModeloModel from 'src/model/veiculo/IcarrosModeloModel'
import WebmotorsModeloModel from 'src/model/veiculo/WebmotorsModeloModel'
import UsadosbrModeloModel from 'src/model/veiculo/UsadosbrModeloModel'
import OlxModeloModel from 'src/model/veiculo/OlxModeloModel'
import MobiautoModeloModel from 'src/model/veiculo/MobiautoModeloModel'
import ConsultaPlacaIcarrosModel from 'src/model/veiculo/ConsultaPlacaIcarrosModel'

export default {
  name: 'VeiculoForm',
  components: { DpForm, Upload, UploadMultiplo, BemTabs },
  mixins: [DpFormMixin, NotificacaoMixin, vTopFilters, CidadeEstado],
  data () {
    return {
      // loja e estoque
      carregandoVeiculo: false,
      precificadorEncontrado: false,
      veiculoId: null,
      lojas: [],
      planosIcarros: [],
      planosWebmotors: [],
      planosMobiauto: [],
      planosOlx: [],
      plano_icarros_id: null,
      plano_webmotors_id: null,
      plano_mobiauto_id: null,
      plano_olx_id: null,
      textColor: '#000',
      editColor: false,
      filtroLojas: '',
      resultadoLojas: [],
      estoques: [],
      carregandoLojas: false,
      carregandoEstoques: false,
      combustiveisCredere: [],
      // anuncio: null,
      // precificador
      precificadores: ['ano_modelo', 'ano_fabricacao'],
      listaPrecificadores: [],
      modalPrecificadores: false,
      // arquivos
      carregandoArquivos: null,
      veiculoArquivos: [],
      veiculoFotosInternas: [],
      veiculoFotoInterna360: [],
      veiculoAvarias: [],
      veiculoLaudos: [],
      // busca de placa
      buscando: false,
      placa: '',
      modalAlpes: false,
      chassi: '',
      // modals
      modalAno: false,
      modalAnoModelo: false,
      modalveiculos: false,
      vistoriaConfirmacao: false,
      // busca de placa
      qtdVersoesEncontradas: 0,
      placaEncontradaVersoes: [],
      buscandoPlaca: false,
      // listagens
      cidades: [],
      buscandoRelacoesVersoes: false,
      buscandoRelacoes: false,
      models: [],
      carregamentos: [],
      listas: [],
      filtrosListagens: [],
      keys: [],
      semSobrecarga: false,
      listagens: [
        'categoria_id',
        'marca_id',
        'modelo_id',
        'versao_id',
        'carroceria_id',
        'cor_id',
        'interna_cor_id',
        'cambio_id',
        'combustivel_id'
      ],
      listagensCreated: [
        'categoria_id',
        'marca_id',
        'carroceria_id',
        'cor_id',
        'interna_cor_id',
        'cambio_id',
        'combustivel_id'
      ],
      bemRelacoes: [],
      mascarasDocumentos: {},
      forceRenderMascaraDocumentos: 0,
      relacoes: [
        'caracteristicas',
        'tags',
        'acessorios',
        'bem_caracteristicas',
        'bem_acessorios',
        'bem_tags'
      ],
      listasRelacoes: [],
      diretivaSemMascara: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false
      },
      tabInterno: '',
      situacoes: [],
      modelPadrao: new VeiculoModel(),
      modelSituacaoAnuncio: new SituacaoAnuncioModel(),

      modelIcarrosMarca: new IcarrosMarcaModel(),
      modelWebmotorsMarca: new WebmotorsMarcaModel(),
      modelUsadosbrMarca: new UsadosbrMarcaModel(),
      modelOlxMarca: new OlxMarcaModel(),
      modelMobiautoMarca: new MobiautoMarcaModel(),

      modelIcarrosModelo: new IcarrosModeloModel(),
      modelWebmotorsModelo: new WebmotorsModeloModel(),
      modelUsadosbrModelo: new UsadosbrModeloModel(),
      modelOlxModelo: new OlxModeloModel(),
      modelMobiautoModelo: new MobiautoModeloModel()

      // modelIcarrosVersao: new IcarrosVersaoModel(),
      // modelWebmotorsVersao: new WebmotorsVersaoModel(),
      // modelUsadosbrVersao: new UsadosbrVersaoModel(),
      // modelOlxVersao: new OlxVersaoModel(),
      // modelMobiautoVersao: new MobiautoVersaoModel()
    }
  },
  props: {
    tiposNegociacao: {
      type: Object,
      default: () => {
      }
    },
    mostrarNavegacao: {
      type: Boolean,
      default: true
    },
    componentTabs: {
      type: Boolean,
      default: true
    },
    veiculoIdExterno: {
      type: Number,
      default: 0
    },
    situacaoIdExterno: {
      type: Number,
      default: 1
    },
    externo: {
      type: Boolean,
      default: false
    },
    anuncio: {
      type: Object,
      default: () => {
      }
    },
    mostrarConteudoTab: {
      type: [Array],
      default: () => [
        'dados',
        'caracteristicas',
        'fotos',
        'fotosInternas',
        'fotosAvarias',
        'fotoInterna',
        'laudos',
        'tipos-de-negociacao',
        'anuncios',
        'ofertas',
        'vistorias',
        'arquivos',
        'remocoes',
        'financeiro',
        'estadias'
      ]
    },
    props: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    veiculoIdExterno: {
      handler (agora, antes) {
        if (agora !== null) {
          this.veiculoId = agora
          if (this.modelPadrao.form.id) {
            this.carregandoVeiculo = false
          } else {
            this.modelPadrao.getId({ id: agora }).then((response) => {
              const dados = response.dados ?? {}
              this.modelPadrao.form = Object.assign({}, dados)
              this.$nextTick(() => {
                this.setarDados()
              })
              if (this.situacaoIdExterno) {
                this.modelPadrao.form.situacao_id = this.situacaoIdExterno
              }
              this.carregandoVeiculo = false
            })
          }
        }
      }
    },
    'modelPadrao.form.proprietario_local_id' (agora) {
      if (typeof agora === 'number' && this.veiculoId) {
        this.buscarEstoques()
      }
    },
    'modelPadrao.form.placa_uf' (agora, antes) {
      if (agora && !antes) {
        const index = this.data.estados.findIndex(es => es.sigla === agora)
        this.cidades = this.data.estados[index].cidades
      }
    },
    'modelPadrao.form.combustivel_id' (agora, antes) {
      if (agora) {
        this.mapearCombustivelNetcarrosCredere(agora)
      }
    },
    'modelPadrao.form.categoria_id' (agora) {
      this.buscarListagemRequest('marca_id', false, `categoria_id:${agora}`)
    },
    'modelPadrao.form.marca_id' (agora, antes) {
      if (agora !== antes && !!antes) {
        this.modelPadrao.form.modelo_id = ''
        this.modelPadrao.form.versao_id = ''
      }
      if (agora !== undefined) {
        this.buscarListagemRequest('modelo_id', false, `marca_id:${agora}`)
      }
    },
    'modelPadrao.form.modelo_id' (agora, antes) {
      if (agora !== antes && !!antes) {
        this.modelPadrao.form.versao_id = ''
      }
      if (agora !== undefined) {
        this.buscarListagemRequest('versao_id', false, `modelo_id:${agora}`)
      }
    },
    'modelPadrao.form.codigo_fipe': {
      handler (agora, antes) {
        if (agora !== antes && !!antes) {
          this.modelPadrao.form.codigo_molicar_1 = ''
        }
        if (agora !== undefined && (agora).length === 8) {
          setTimeout(() => {
            this.buscarListagemRequest('codigo_molicar', false, `fipe:${agora}`, false, 'versao')
          }, 1000)
        }
      }
    },
    'modelPadrao.form.ultimo_proprietario_tipo_documento' () {
      this.forceRenderMascaraDocumentos++
    },
    'modelPadrao.form.zero_km': {
      handler (agora) {
        if (agora === 1) {
          this.modelPadrao.form.km = 0
          this.modelPadrao.form.placa = ''
          this.modelPadrao.form.codigo_molicar = ''
        }
      }
    },
    'modelPadrao.form.versao_id': {
      handler (agora, antes) {
        if (agora !== antes && agora !== null) {
          this.buscarPrecificadores('ano_modelo')
        }
      }
    },
    'modelPadrao.form.ano_modelo': {
      handler (agora) {
        if (agora === 'Escolha um ano') {
          this.modelPadrao.form.ano_modelo = ''
          this.listaPrecificadores['ano_modelo'].modal = !this
            .listaPrecificadores['ano_modelo'].modal
        } else {
          let anos = clone(this.listaPrecificadores['ano_modelo'].lista)
          anos = anos.filter(a => parseInt(a.ano) <= parseInt(agora))
          if (Array.isArray(this.listaPrecificadores['ano_fabricacao'].lista)) {
            const lista = Object.assign({}, this.listaPrecificadores)
            lista['ano_fabricacao'].lista = ['Escolha um ano']
            lista['ano_fabricacao'].lista = Object.assign([], [...lista['ano_fabricacao'].lista, ...anos])
            this.listaPrecificadores = Object.assign({}, lista)
          }
        }
        if (agora && agora !== null && (agora).length === 4) {
          this.precificadorEncontrado = false
          this.buscarPrecificadores('ano_modelo')
        }
      }
    },
    'modelPadrao.form.ano_fabricacao': {
      handler (agora) {
        if (agora === 'Escolha um ano') {
          this.modelPadrao.form.ano_fabricacao = ''
          this.listaPrecificadores['ano_fabricacao'].modal = !this
            .listaPrecificadores['ano_fabricacao'].modal
        }
      }
    },
    tabInterno: {
      handler (agora) {
        if (agora === 'caracteristicas') {
          if (
            this.veiculoId &&
            this.listasRelacoes['acessorios'].lista.length === 0
          ) {
            this.buscarTodasRelacoesListagens()
          }
        }
        if (agora === 'fotos' && this.veiculoId) {
          this.sincronizarFotos(true)
        }
        if (agora === 'fotosInternas' && this.veiculoId) {
          this.sincronizarFotosInternas(true)
        }
        if (agora === 'fotoInterna360' && this.veiculoId) {
          this.sincronizarFotosInternas360(true)
        }
        if (agora === 'fotosAvarias' && this.veiculoId) {
          this.sincronizarFotosAvarias(true)
        }
        if (agora === 'laudos' && this.veiculoId) {
          this.sincronizarLaudos(true)
        }
        if (agora === 'tipo-de-negociacao' && this.veiculoId) {
          if (this.carregandoArquivos === null) {
            this.sincronizarFotos(false)
          }
        }
        if (agora === 'anuncios' && this.veiculoId) {
          // this.sincronizarPlanosIcarros()
        //  this.sincronizarPlanosWebmotors()
        }
      }
    }
  },
  computed: {
    permitirBuscarAcessoriosVersao () {
      if (this.bemRelacoes['veiculo_acessorios']) {
        return Object.keys(this.bemRelacoes['veiculo_acessorios']).every(
          key => !this.bemRelacoes['veiculo_acessorios'][key].model)
      } else {
        return false
      }
    }
  },
  created () {
    this.getSituacoes()
    this.carregandoVeiculo = true
    if (this.externo && !this.veiculoIdExterno) {
      this.carregandoVeiculo = false
    }
    if (this.externo && this.veiculoIdExterno) {
      this.veiculoId = this.veiculoIdExterno
      this.carregandoVeiculo = false
    }
    if (!this.externo) {
      this.veiculoId = this.$route.params.id
      this.carregandoVeiculo = false
    }
    this.filtrosListagens = this.listagens.reduce((acc, atual) => {
      let buscasPermitidas = [
        'categoria_id',
        'marca_id',
        'modelo_id',
        'versao_id',
        'cor_id'
      ]
      acc[`${atual}`] = {
        filtrado: [],
        termo: '',
        permitirBusca: buscasPermitidas.includes(atual)
      }

      return acc
    }, [])

    this.listaPrecificadores = this.precificadores.reduce((acc, atual) => {
      acc[`${atual}`] = {
        carregando: false,
        lista: ['Escolha um ano'],
        modal: false
      }
      return acc
    }, [])

    this.listasRelacoes = this.relacoes.reduce((acc, atual) => {
      acc[`${atual}`] = {
        carregando: false,
        lista: []
      }

      return acc
    }, [])
    this.mascarasDocumentos = {
      'CPF': '###.###.###-##',
      'CNPJ': '##.###.###/####-##',
      'RG': '##.###.###-#',
      'CNH': '###########'
    }
  },
  mounted () {
    LocalStorage.remove('veiculo-listas')
    this.buscarLojas()
    this.buscarPlanosIcarros()
    this.buscarPlanosWebmotors()
    this.buscarPlanosMobiauto()

    this.buscarPlanosOlx()
    if (LocalStorage.has('veiculo-listas')) {
      let listas = LocalStorage.getItem('veiculo-listas')
      if (!listas['dominios-credere']) {
        const model = new DominioCredereModel()
        model.getListagem().then((response) => {
          const lista = response.dados
          this.combustiveisCredere = lista['fuel-type']
          listas['dominios-credere'] = lista
          LocalStorage.set('veiculo-listas', listas)
        })
      } else {
        const listas = LocalStorage.getItem('veiculo-listas')
        this.combustiveisCredere = listas['dominios-credere']['fuel-type']
      }
    }

    this.listas = this.listagens.reduce((acc, atual) => {
      acc[`${atual}`] = []
      return acc
    }, {})
    this.listas['codigo_molicar'] = []
    this.carregamentos = this.listagens.reduce((acc, atual) => {
      acc[`${atual}`] = false

      return acc
    }, {})
    this.carregamentos['codigo_molicar'] = false
    this.keys = this.listagens.reduce((acc, atual, index) => {
      acc[`${atual}`] = index + 1

      return acc
    }, [])
    this.keys['codigo_molicar'] = 0
    this.models = {
      categoria_id: CategoriaModel,
      marca_id: MarcaModel,
      modelo_id: ModeloModel,
      versao_id: VersaoModel,
      codigo_molicar: MolicarModel,
      carroceria_id: CarroceriaModel,
      cor_id: CorModel,
      interna_cor_id: CorModel,
      cambio_id: CambioModel,
      combustivel_id: CombustivelModel,
      caracteristicas: CaracteristicaModel,
      tags: TagModel,
      acessorios: AcessorioModel,
      veiculo_caracteristicas: CaracteristicaModel,
      veiculo_acessorios: AcessorioModel,
      veiculo_tags: TagModel
    }

    this.listaPrecificadores.ano_modelo.carregando = true
    if (LocalStorage.has('veiculo-listas')) {
      const listas = LocalStorage.getItem('veiculo-listas')
      this.listas = listas
    } else {
      // buscar todas as listagens
      this.buscarTodasAsListagens()
    }
  },
  filters: {
    tipoNome (tipo) {
      if (tipo) {
        if (tipo === 'tipo_retomada_id') {
          return 'Origem de entrada'
        }
        if (tipo === 'interna_cor_id') {
          return 'Cor interna'
        }
        let nome = tipo.replace('_id', '')
        if (nome) {
          return nome.charAt(0).toUpperCase() + nome.slice(1)
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    tipoNomePrecificador (tipo) {
      if (tipo) {
        let nome = tipo.replace('_', ' ')
        if (nome) {
          let n = nome.split(' ')
          if (n[1] === 'fabricacao') {
            n[1] = n[1].replace('cao', 'ção')
          }
          nome = n.join(' ')
          return nome.charAt(0).toUpperCase() + nome.slice(1)
        } else {
          return ''
        }
      } else {
        return ''
      }
    }
  },
  methods: {
    Color () {
      this.editColor = true
    },
    applyColor () {
      document.execCommand('foreColor', false, this.textColor)
      this.editColor = false
    },
    excluirReferenciaAlpes () {
      this.$emit('excluir-alpes')
      this.modalAlpes = false
    },
    toolbarEditor (q) {
      return [
        [
          {
            label: q.lang.editor.align,
            icon: q.iconSet.editor.align,
            fixedLabel: true,
            options: ['left', 'center', 'right', 'justify']
          }
        ],
        ['bold', 'italic', 'strike', 'underline', 'subscript', 'superscript', 'fontColor'],
        ['token', 'hr', 'link', 'custom_btn'],
        ['fullscreen'],
        [
          {
            label: q.lang.editor.formatting,
            icon: q.iconSet.editor.formatting,
            list: 'no-icons',
            options: [
              'p',
              'h1',
              'h2',
              'h3',
              'h4',
              'h5',
              'h6',
              'code'
            ]
          },
          {
            icon: q.iconSet.editor.fontSize,
            fixedLabel: true,
            fixedIcon: false,
            list: 'no-icons',
            options: [
              'size-1',
              'size-2',
              'size-3',
              'size-4',
              'size-5',
              'size-6',
              'size-7'
            ]
          },
          {
            icon: q.iconSet.editor.font,
            fixedLabel: false,
            fixedIcon: true,
            list: 'no-icons',
            options: [
              'default_font',
              'arial',
              'arial_black',
              'comic_sans',
              'courier_new',
              'impact',
              'lucida_grande',
              'times_new_roman',
              'verdana'
            ]
          },
          'removeFormat'
        ],
        ['unordered', 'ordered', 'outdent', 'indent'],

        ['undo', 'redo'],
        ['viewsource']
      ]
    },
    fontsEditor () {
      return {
        arial: 'Arial',
        arial_black: 'Arial Black',
        comic_sans: 'Comic Sans MS',
        courier_new: 'Courier New',
        impact: 'Impact',
        lucida_grande: 'Lucida Grande',
        times_new_roman: 'Times New Roman',
        verdana: 'Verdana'
      }
    },
    removeDuplicates (myArr, prop, incluir = null) {
      return myArr.filter((obj, pos, arr) => {
        if (incluir !== null) {
          return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos || incluir === obj['id']
        } else {
          return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos
        }
      })
    },
    getSituacoes (exceto = 0) {
      this.modelSituacaoAnuncio.getListagem().then((res) => {
        this.situacoes = res.dados
      }).catch(erro => {
        this.notificacao(erro.msg)
        console.error(erro)
      })
    },
    mapearCombustivelNetcarrosCredere (tipo) {
      const combustivel = this.listas['combustivel_id'].find(c => c.id === tipo)
      const mapCombustiveisCredere = {
        'Gasolina': 'Gasolina',
        'Álcool': 'Álcool',
        'Diesel': 'Diesel',
        'GNV': 'Gás Natural Veícular',
        'Flex': 'Flex',
        'Híbrido / Elétrico': 'Elétrico',
        'MultiFlex (Alc, Gas, GNV)': 'Híbrido'
      }
      if (combustivel && combustivel.nome) {
        this.modelPadrao.form.credere_fuel_type = mapCombustiveisCredere[combustivel.nome ?? 'Flex']
      } else {
        this.modelPadrao.form.credere_fuel_type = mapCombustiveisCredere['Flex']
      }
    },
    definirPrecificador (e, precificador) {
      const form = Object.assign({}, this.modelPadrao.form)
      form[precificador] = e
      this.modelPadrao.form = Object.assign({}, form)
    },
    definirEstadoCidade (e) {
      this.cidades = e.cidades
      const form = clone(this.modelPadrao.form)
      form.placa_uf = e.sigla
      form.placa_cidade = ''
      this.modelPadrao.form = Object.assign({}, form)
    },
    alterarOrdemArquivos (e) {
      const arquivos = e.arquivos
      const model = new VeiculoModel(true)
      const promises = arquivos.reduce((acc, atual, index) => {
        acc = acc.concat(model.salvar({ id: atual, prioridade: index + 1 }))
        return acc
      }, [])

      Promise.all(promises).then(() => {
        if (parseInt(e.arquivos[0].tipo_arquivo_id) === 1) {
          this.sincronizarFotos(true)
        } else if (parseInt(e.arquivos[0].tipo_arquivo_id) === 3) {
          this.sincronizarFotosInternas(true)
        } else if (parseInt(e.arquivos[0].tipo_arquivo_id) === 6) {
          this.sincronizarLaudos(true)
        } else if (parseInt(e.arquivos[0].tipo_arquivo_id) === 7) {
          this.sincronizarFotoInterna360(true)
        } else if (parseInt(e.arquivos[0].tipo_arquivo_id) === 8) {
          this.sincronizarFotosAvarias(true)
        }
      })
    },
    removerArquivo (e) {
      const id = e.id
      const tipo = e.tipo
      this.notificacao('enviando', 'Excluindo arquivo. Aguarde', 3000)
      const model = new VeiculoModel(true)
      console.log('excluir ' + id)
      model.deletar(id).then(() => {
        console.log('excluído ', e.tipo, tipo)
        if (parseInt(e.tipo) === 1) {
          this.sincronizarFotos(true)
        } else if (parseInt(e.tipo) === 3) {
          this.sincronizarFotosInternas(true)
        } else if (parseInt(e.tipo) === 6) {
          this.sincronizarLaudos(true)
        } else if (parseInt(e.tipo) === 7) {
          this.sincronizarFotoInterna360(true)
        } else if (parseInt(e.tipo) === 8) {
          this.sincronizarFotosAvarias(true)
        } else {
          console.log(e.tipo)
        }
        this.forceRender++
      })
    },
    atualizarArquivos (e) {
      const model = new VeiculoModel(true)
      const arquivos = e.arquivos
      const promises = []
      arquivos.map((a, index) => {
        let prioridade = (index + 1)
        if (parseInt(a.tipo_arquivo_id) === 1) { // Fotos Externas
          prioridade += this.veiculoArquivos.length
        } else if (parseInt(a.tipo_arquivo_id) === 3) { // Fotos Internas
          prioridade += this.veiculoFotosInternas.length
        } else if (parseInt(a.tipo_arquivo_id) === 6) { // PDF
          prioridade += this.veiculoLaudos.length
        } else if (parseInt(a.tipo_arquivo_id) === 7) { // Foto Interna 360
          prioridade += this.veiculoFotoInterna360.length
        } else if (parseInt(a.tipo_arquivo_id) === 8) { // Avarias
          prioridade += this.veiculoAvarias.length
        }
        const form = {
          bem_id: this.veiculoId,
          prioridade,
          arquivo_url: a.arquivo,
          ...a
        }
        promises.push(model.salvar(form))
      })
      Promise.all(promises).then(() => {
        if (parseInt(e.arquivos[0].tipo_arquivo_id) === 1) {
          this.sincronizarFotos(true)
        } else if (parseInt(e.arquivos[0].tipo_arquivo_id) === 3) {
          this.sincronizarFotosInternas(true)
        } else if (parseInt(e.arquivos[0].tipo_arquivo_id) === 6) {
          this.sincronizarLaudos(true)
        } else if (parseInt(e.arquivos[0].tipo_arquivo_id) === 7) {
          this.sincronizarFotoInterna360(true)
        } else if (parseInt(e.arquivos[0].tipo_arquivo_id) === 8) {
          this.sincronizarFotosAvarias(true)
        }
      })
    },
    sincronizarFotos (sincronizando) {
      const model = new VeiculoModel(true)
      this.carregandoArquivos = true
      if (sincronizando) {
        this.$q.loading.show({
          message: 'Sincronizando arquivos, aguarde...'
        })
      }
      model.getListagem({
        params: {
          search: `bem_id:${this.veiculoId};tipo_arquivo_id:1`,
          searchJoin: 'and',
          limit: 1000,
          orderBy: 'prioridade',
          sortedBy: 'asc'
        }
      }).then((response) => {
        if (sincronizando) {
          this.$q.loading.hide()
        }
        const arquivos = response.dados ?? []
        this.veiculoArquivos = arquivos
        this.carregandoArquivos = false
      }).catch(() => {
        this.carregandoArquivos = false
        if (sincronizando) {
          this.carregandoArquivos = true
        }
      })
    },
    sincronizarFotosInternas (sincronizando) {
      const model = new VeiculoModel(true)
      this.carregandoArquivos = true
      if (sincronizando) {
        this.$q.loading.show({
          message: 'Sincronizando arquivos, aguarde...'
        })
      }
      model.getListagem({
        params: {
          search: `bem_id:${this.veiculoId};tipo_arquivo_id:3`,
          searchJoin: 'and',
          limit: 1000,
          orderBy: 'prioridade',
          sortedBy: 'asc'
        }
      }).then((response) => {
        if (sincronizando) {
          this.$q.loading.hide()
        }
        const arquivos = response.dados ?? []
        this.veiculoFotosInternas = arquivos
        this.carregandoArquivos = false
      }).catch(() => {
        this.carregandoArquivos = false
        if (sincronizando) {
          this.carregandoArquivos = true
        }
      })
    },
    sincronizarFotosAvarias (sincronizando) {
      const model = new VeiculoModel(true)
      this.carregandoArquivos = true
      if (sincronizando) {
        this.$q.loading.show({
          message: 'Sincronizando arquivos, aguarde...'
        })
      }
      model.getListagem({
        params: {
          search: `bem_id:${this.veiculoId};tipo_arquivo_id:8`,
          searchJoin: 'and',
          limit: 1000,
          orderBy: 'prioridade',
          sortedBy: 'asc'
        }
      }).then((response) => {
        if (sincronizando) {
          this.$q.loading.hide()
        }
        const arquivos = response.dados ?? []
        this.veiculoAvarias = arquivos
        this.carregandoArquivos = false
      }).catch(() => {
        this.carregandoArquivos = false
        if (sincronizando) {
          this.carregandoArquivos = true
        }
      })
    },
    sincronizarLaudos (sincronizando) {
      const model = new VeiculoModel(true)
      this.carregandoArquivos = true
      if (sincronizando) {
        this.$q.loading.show({
          message: 'Sincronizando arquivos, aguarde...'
        })
      }
      model.getListagem({
        params: {
          search: `bem_id:${this.veiculoId};tipo_arquivo_id:6`,
          searchJoin: 'and',
          orderBy: 'prioridade',
          limit: 1000,
          sortedBy: 'asc'
        }
      }).then((response) => {
        if (sincronizando) {
          this.$q.loading.hide()
        }
        const arquivos = response.dados ?? []
        this.veiculoLaudos = arquivos
        this.carregandoArquivos = false
      }).catch(() => {
        this.carregandoArquivos = false
        if (sincronizando) {
          this.carregandoArquivos = true
        }
      })
    },
    sincronizarFotosInternas360 (sincronizando) {
      const model = new VeiculoModel(true)
      this.carregandoArquivos = true
      if (sincronizando) {
        this.$q.loading.show({
          message: 'Sincronizando arquivos, aguarde...'
        })
      }
      model.getListagem({
        params: {
          search: `bem_id:${this.veiculoId};tipo_arquivo_id:7`,
          searchJoin: 'and',
          orderBy: 'prioridade',
          limit: 1000,
          sortedBy: 'asc'
        }
      }).then((response) => {
        if (sincronizando) {
          this.$q.loading.hide()
        }
        const arquivos = response.dados ?? []
        this.veiculoFotoInterna = arquivos
        this.carregandoArquivos = false
      }).catch(() => {
        this.carregandoArquivos = false
        if (sincronizando) {
          this.carregandoArquivos = true
        }
      })
    },
    alterarRelacao (e, relacao, id, promise) {
      this.bemRelacoes[`veiculo_${relacao}`][id].model = e
      const atributo = clone(relacao).slice(0, -1)
      const model = new this.models[`${relacao}`](true)
      if (e === 1) {
        const form = { bem_id: this.veiculoId, [`${atributo}_id`]: id }
        if (promise) {
          return model.salvar(form)
        }
        model
          .salvar(form)
          .then(() => {
            // nada
          })
          .catch(() => {
            // desmarcar se não salvou
            this.bemRelacoes[`veiculo_${relacao}`][id] = !e
          })
      } else {
        const recursoId = this.bemRelacoes[`veiculo_${relacao}`][`${id}`].id
        model
          .deletar(recursoId)
          .then(() => {
            // nada
          })
          .catch(() => {
            // deixar marcado se nao apagou
            this.bemRelacoes[`veiculo_${relacao}`][id].model = !e
          })
      }
    },
    receberTabAtual (e) {
      this.tabInterno = e.tab
    },
    makeid (length) {
      var result = ''
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      var characters1 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
      var characters2 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      var characters3 = '0123456789'
      var charactersLength = characters1.length
      for (var i = 0; i < length; i++) {
        if (i === 3) {
          result += characters2.charAt(Math.floor(Math.random() * characters2.length))
        }
        if (i === 5 || i === 6) {
          result += characters3.charAt(Math.floor(Math.random() * characters3.length))
        }
        if ([3, 5, 6].indexOf(i) === -1) {
          result += characters2.charAt(Math.floor(Math.random() * characters2.length))
        }
      }
      this.modelPadrao.form = Object.assign({}, this.modelPadrao.form, { spincar: result })
      return result
    },
    // Salvar form
    enviarForm () {
      // if (!(this.modelPadrao.form.precificador_versao_id > 0)) {
      //   this.notificacao('erro', 'Precificador não foi encontrado!! Verificar ano fabricacao e ano modelo', 6000)
      //   return
      // }
      let form = clone(this.modelPadrao.form)// if (form.zero_km !== 0) {
      if (form.zero_km !== 0) {
        form.placa = form.spincar
      }
      if (!(form.km > 0)) {
        form.km = 0
      }
      this.notificacao('enviando', 'Salvando veículo. Aguarde !')
      form.situacao_id = 1
      this.modelPadrao
        .salvar(form)
        .then(response => {
          if (!this.veiculoId) {
            this.modelPadrao.form.id = response.dados.id
            this.buscarTodasRelacoesListagens()
            // this.buscarTodasRelacoesListagens(response.dados.versao_id)
            if (this.externo) {
              const { versao } = response.dados
              const { modelo } = versao
              const { marca } = modelo
              this.$emit('dados-veiculo', {
                dados: response.dados,
                pronta_entrega: clone(this.anuncio.pronta_entrega),
                outlet: clone(this.anuncio.outlet),
                ipva_pago: clone(this.anuncio.ipva_pago),
                aplicativo: clone(this.anuncio.aplicativo),
                blackfriday: clone(this.anuncio.blackfriday),
                cyberweek: clone(this.anuncio.cyberweek),
                anuncioNome: `${marca.nome} ${modelo.nome} ${versao.nome}`
              })
              this.veiculoId = null
            } else {
              // this.$router.push({
              //   name: 'bens.form',
              //   params: { id: response.dados.id }
              // })
              // this.veiculoId = response.dados.id
              this.$router.push({ name: 'anuncios.grid' })
            }
          } else {
            if (this.externo) {
              this.$emit('atualizar-situacao', {
                dados: {
                  situacao_id: clone(this.modelPadrao.form.situacao_id),
                  pronta_entrega: clone(this.anuncio.pronta_entrega),
                  outlet: clone(this.anuncio.outlet),
                  ipva_pago: clone(this.anuncio.ipva_pago),
                  aplicativo: clone(this.anuncio.aplicativo),
                  blackfriday: clone(this.anuncio.blackfriday),
                  cyberweek: clone(this.anuncio.cyberweek)
                }
              })
            }
          }
          this.notificacao('salvo', 'Veículo salvo com sucesso !')
        })
        .catch(error => {
          this.notificacao('erro', error.msg)
        })
    },
    buscarTodasRelacoesListagens (buscarAcessorioRelacao = null) {
      let promises = []
      promises.push(
        this.buscarListagemRequest('caracteristicas', true),
        this.buscarListagemRequest('tags', true),
        this.buscarListagemRequest('acessorios', true)
      )
      this.buscandoRelacoes = true
      Promise.all(promises).then(response => {
        const relacoes = ['caracteristicas', 'tags', 'acessorios']
        const model = relacoes.reduce((acc, key, index) => {
          this.listasRelacoes[key].lista = response[index].dados ?? []
          let dados = response[index].dados ?? []
          dados = dados.reduce((acc, atual) => {
            acc[`${atual.id}`] = { id: null, model: 0 }
            return acc
          }, {})
          acc[`veiculo_${key}`] = dados

          return acc
        }, {})
        this.bemRelacoes = Object.assign({}, model)
        LocalStorage.set('veiculo-relacoes', this.listasRelacoes)
        LocalStorage.getItem('veiculo-relacoes')
        if (buscarAcessorioRelacao) {
          this.buscarAcessorioRelacoes(buscarAcessorioRelacao)
        } else {
          this.buscarVeiculoRelacoes()
        }
      })
    },
    buscarAcessorioRelacoes (versaoId) {
      const model = new AcessorioModel(false, true)
      model
        .getListagem({ params: { search: `versao_id:${versaoId}` } })
        .then(response => {
          const dados = response.dados ?? []
          const existentes = dados.reduce((acc, atual) => {
            acc[`${atual[`acessorio_id`]}`] = { id: atual.id, model: 1 }
            return acc
          }, {})
        })
    },
    buscarAcessoriosDaVersao () {
      const model = new VersaoModel(true)
      this.buscandoRelacoesVersoes = true
      model.getId({ id: this.modelPadrao.form.versao_id }).then((response) => {
        if (response.dados !== null) {
          let acessorios = response.dados.acessorios_relacoes.reduce((acc, atual) => {
            acc = acc.concat(atual.acessorio_id)
            return acc
          }, [])
          if (acessorios.length > 0) {
            let atuais = clone(this.bemRelacoes['veiculo_acessorios'])
            let promises = []
            acessorios.forEach((key) => {
              if (typeof atuais[key] !== 'undefined') {
                promises.push(this.alterarRelacao(1, 'acessorios', key, true))
              }
              atuais[key] = 1
            })
            Promise.all(promises).then(() => {
              this.buscandoRelacoesVersoes = false
              this.notificacao('salvo', 'Acessórios encontrados')
              // this.bemRelacoes['veiculo_acessorios'] = Object.assign({}, atuais)
            })
          } else {
            this.buscandoRelacoesVersoes = false
            this.notificacao('erro', 'Não foram encontrados acessórios para esta versão')
          }
        } else {
          this.notificacao('erro', 'Não foram encontrados acessórios para esta versão')
        }
      }).catch((error) => {
        this.notificacao('erro', error.msg)
        this.buscandoRelacoesVersoes = false
      })
    },
    buscarVeiculoRelacoes () {
      let promises = []
      promises.push(
        this.buscarListagemRequest(
          'veiculo_caracteristicas',
          true,
          `bem_id:${this.veiculoId}`,
          true
        ),
        this.buscarListagemRequest(
          'veiculo_acessorios',
          true,
          `bem_id:${this.veiculoId}`,
          true
        ),
        this.buscarListagemRequest(
          'veiculo_tags',
          true,
          `bem_id:${this.veiculoId}`,
          true
        )
      )
      Promise.all(promises).then(response => {
        const tipos = [
          'veiculo_caracteristicas',
          'veiculo_acessorios',
          'veiculo_tags'
        ]
        tipos.map((key, index) => {
          const nomeCampo = clone(key)
            .replace('veiculo_', '')
            .slice(0, -1)
          const relacoes = response[index].dados ?? []
          const existentes = relacoes.reduce((acc, atual) => {
            acc[`${atual[`${nomeCampo}_id`]}`] = { id: atual.id, model: 1 }
            return acc
          }, {})
          let atuais = clone(this.bemRelacoes[key])
          atuais = { ...atuais, ...existentes }
          this.bemRelacoes[key] = Object.assign({}, atuais)
          this.buscandoRelacoes = false
        })
      })
    },
    // Define marca, modelo e versao apos o primeiro carregamento
    setarDados () {
      let form = clone(this.modelPadrao.form)
      if (form.versao.modelo) {
        form.modelo_id = form.versao.modelo.id
        form.marca_id = form.versao.modelo.marca.id
        form.categoria_id = form.versao.modelo.marca.categoria_id
      }
      this.modelPadrao.form = Object.assign({}, form)
    },
    setarLaudo (e) {
      console.log('oapaa', e)
      this.modelPadrao.form.laudo_arquivo = e.arquivo
      this.modelPadrao.form.laudo_arquivo_url_completa = e.arquivo_url_completa
    },

    // Listagens
    buscarListagem (tipo) {
      if (this.listagens.includes(tipo)) {
        switch (tipo) {
          case 'marca_id':
            this.buscarListagemRequest(tipo, false, `categoria_id:${this.modelPadrao.form.categoria_id}`)
            break
          case 'modelo_id':
            this.buscarListagemRequest(tipo, false, `marca_id:${this.modelPadrao.form.marca_id}`)
            break
          case 'versao_id':
            this.buscarListagemRequest(tipo, false, `modelo_id:${this.modelPadrao.form.modelo_id}`)
            break
          default:
            this.buscarListagemRequest(tipo)
            break
        }
      }
    },
    buscarTodasAsListagens () {
      this.buscandoTodasAsListagens = true
      let promises = this.listagens.reduce((acc, tipo) => {
        if (tipo !== 'modelo_id' && tipo !== 'versao_id') {
          let model = new this.models[tipo]()
          // if (!['marca_id', 'modelo_id', 'versao_id'].find(t => t === tipo)) {
          //   console.warn('opaaa')
          //   acc = acc.concat(model.getListagem({ params: { limit: 20 } }))
          // } else {
          //   acc = acc.concat(model.getListagem({ params: { limit: 1 } }))
          // }
          acc = acc.concat(model.getListagem({ params: { limit: 1000, orderBy: 'nome', sortedBy: 'asc' } }))
          return acc
        } else {
          acc = acc.concat(new Promise((resolve) => {
            resolve(true)
          }))
          return acc
        }
      }, [])
      Promise.all(promises)
        .then(response => {
          this.listagens.forEach((tipo, index) => {
            this.listas[tipo] = response[index].dados ?? []
          })
          let listas = this.listas
          if (LocalStorage.has('veiculo-listas')) {
            listas = LocalStorage.getItem('veiculo-listas')
            listas = [...listas, ...this.listas]
          }
          LocalStorage.set('veiculo-listas', this.listas)
          this.buscandoTodasAsListagens = false
          this.buscarPrecificadores('ano_modelo')
        })
        .catch(() => {
          this.buscandoTodasAsListagens = false
        })

      this.modelWebmotorsMarca.getListagem().then((response) => {
        this.codigos_webmotors = response.dados ?? []
      }).catch((error) => {
        this.notificacao('erro', error.msg)
      })
    },
    buscarListagemRequest (tipo, promise, search = '', relacao = false, orderBy = 'nome') {
      let model = new this.models[`${tipo}`](relacao)
      this.carregamentos[`${tipo}`] = true
      if (promise) {
        return model.getListagem({ params: { search, limit: 1000, orderBy: relacao ? '' : orderBy, sortedBy: 'asc' } })
      } else {
        model
          .getListagem({ params: { search, limit: 1000, orderBy, sortedBy: 'asc' } })
          .then(response => {
            this.carregamentos[`${tipo}`] = false

            if (tipo === 'versao_id') {
              let data = []
              data[1] = this.removeDuplicates(response.dados, 'nome', this.modelPadrao.form.versao_id)
              response.dados = data[1]
            }

            this.listas[`${tipo}`] = response.dados ?? []
            if (LocalStorage.has('veiculo-listas')) {
              let listas = LocalStorage.getItem('veiculo-listas')
              listas[`${tipo}`] = response.dados
              LocalStorage.set(`veiculo-listas`, listas)
              this.keys[`${tipo}`]++
            }
            if (tipo === 'versao_id') {
              setTimeout(() => {
                this.buscarPrecificadores('ano_modelo')
              }, 1250)
            }
          })
          .catch((ex) => {
            console.error(ex)
          })
        this.carregamentos[`${tipo}`] = false
      }
    },
    filtrarListagem (tipo) {
      const listas = LocalStorage.getItem('veiculo-listas')
      let lista = clone(listas[`${tipo}`])
      const regex = new RegExp(this.filtrosListagens[`${tipo}`].termo, 'i')

      if (!lista || lista.length === 0) lista = this.listas[`${tipo}`]

      this.filtrosListagens[`${tipo}`].filtrado = lista.filter(l => l.nome.match(regex))
      this.listas[`${tipo}`] = this.filtrosListagens[`${tipo}`].filtrado
    },
    resetarListagem (tipo) {
      // const listas = LocalStorage.getItem('veiculo-listas')
      // console.warn('marca: ', listas[tipo])
      // const lista = Object.assign([], listas[`${tipo}`])
      // this.listas[`${tipo}`] = Object.assign([], lista)
      // this.filtrosListagens[`${tipo}`].termo = ''
      // this.keys[`${tipo}`]++
      // this.$nextTick(() => {
      //   if (this.$refs[`select_${tipo}`][0]) {
      //     this.$refs[`select_${tipo}`][0].showPopup()
      //   }
      // })

      this.filtrosListagens[`${tipo}`].termo = ''
      this.buscarListagem(tipo)
    },
    buscarLojas () {
      let model = new LocalModel()
      model.recurso = 'meus-locais'
      this.carregandoLojas = true
      model
        .getListagem()
        .then(response => {
          this.lojas = response.dados ?? []
          this.carregandoLojas = false
        })
        .catch(erro => {
          console.warn(erro.msg)
          this.carregandoLojas = false
        })
    },
    buscarEstoques () {
      let model = new LocalModel()
      this.carregandoEstoques = true
      model
        .getListagem({
          params: {
            search: `pai_local_id:${this.modelPadrao.form.proprietario_local_id}`
          }
        })
        .then(response => {
          this.estoques = response.dados ?? []
          this.carregandoEstoques = false
        })
        .catch(() => {
          this.carregandoEstoques = false
        })
    },
    setarImagem (e) {
      this.modelPadrao.form.foto = e.arquivo
      this.modelPadrao.form.foto_url_completa = e.arquivo.arquivo_url_completa
    },
    // Filtros
    filtrarLojas () {
      const lojas = clone(this.lojas)
      const regex = new RegExp(this.filtroLojas, 'i')
      this.resultadoLojas = lojas.filter(loja => loja.nome.match(regex))
    },
    // Publicação
    async publicarMidia (midia) {
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + localStorage.getItem('tokenBasica')
      let response = []
      try {
        response = await axios.post(process.env.API['automotor'] + `/${midia}/incluir`, {
          bem_id: this.veiculoId,
          plano_id: this.anuncio[midia + '_plano']
        })
        if (response.data.dados) {
          if (midia === 'icarros') {
            this.anuncio = { ...this.anuncio, icarros_codigo: response.data.dados.anuncio.icarros_codigo, icarros_inclusao: response.data.dados.anuncio.icarros_inclusao }
          }
          if (midia === 'webmotors') {
            this.anuncio = { ...this.anuncio, webmotors_codigo: response.data.dados.anuncio.webmotors_codigo, webmotors_inclusao: response.data.dados.anuncio.webmotors_inclusao }
          }
          if (midia === 'mobiauto') {
            this.anuncio = { ...this.anuncio, mobiauto_codigo: response.data.dados.anuncio.mobiauto_codigo, mobiauto_inclusao: response.data.dados.anuncio.mobiauto_inclusao }
          }
          if (midia === 'usadosbr') {
            this.anuncio = { ...this.anuncio, usadosbr_codigo: response.data.dados.anuncio.usadosbr_codigo, usadosbr_inclusao: response.data.dados.anuncio.usadosbr_inclusao }
          }
          if (midia === 'olx') {
            this.anuncio = { ...this.anuncio, olx_codigo: response.data.dados.anuncio.olx_codigo, olx_inclusao: response.data.dados.anuncio.olx_inclusao }
          }
        }

        // this.planosIcarros = response.data.dados ?? []
        // console.log(this.planosIcarros)
      } catch (error) {
        this.notificacao('erro', 'Não foi possível publicar ' + midia)
      }
    },
    async removerMidia (midia) {
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + localStorage.getItem('tokenBasica')
      let response = []
      try {
        response = await axios.post(process.env.API['automotor'] + `/${midia}/remover`, {
          bem_id: this.veiculoId
        })
        if (response.data.dados) {
          if (midia === 'icarros') {
            this.anuncio = { ...this.anuncio, icarros_codigo: response.data.dados.anuncio.icarros_codigo, icarros_exclusao: response.data.dados.anuncio.icarros_exclusao }
          }
          if (midia === 'webmotors') {
            this.anuncio = { ...this.anuncio, webmotors_codigo: response.data.dados.anuncio.webmotors_codigo, webmotors_exclusao: response.data.dados.anuncio.webmotors_exclusao }
          }
          if (midia === 'mobiauto') {
            this.anuncio = { ...this.anuncio, mobiauto_codigo: response.data.dados.anuncio.mobiauto_codigo, mobiauto_exclusao: response.data.dados.anuncio.mobiauto_exclusao }
          }
          if (midia === 'usadosbr') {
            this.anuncio = { ...this.anuncio, usadosbr_codigo: response.data.dados.anuncio.usadosbr_codigo, usadosbr_exclusao: response.data.dados.anuncio.usadosbr_exclusao }
          }
          if (midia === 'olx') {
            this.anuncio = { ...this.anuncio, olx_codigo: response.data.dados.anuncio.olx_codigo, olx_exclusao: response.data.dados.anuncio.olx_exclusao }
          }
        }

        // this.planosIcarros = response.data.dados ?? []
        // console.log(this.planosIcarros)
      } catch (error) {
        this.notificacao('erro', 'Não foi possível publicar ' + midia)
      }
    },
    // Planos
    async buscarPlanosIcarros () {
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + localStorage.getItem('tokenBasica')
      let response = []
      try {
        response = await axios.get(process.env.API['automotor'] + '/icarros/planos/' + (this.modelPadrao.form.zero_km ? 1 : 0))
        console.log(response)

        this.planosIcarros = response.data.dados ?? []
        console.log(this.planosIcarros)
      } catch (error) {
        this.notificacao('erro', 'Não foi possível consultar planos icarros')
      }
    },
    async buscarPlanosWebmotors () {
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + localStorage.getItem('tokenBasica')
      let response = []
      try {
        response = await axios.get(process.env.API['automotor'] + '/webmotors/planos/' + (this.modelPadrao.form.zero_km ? 1 : 0))
        this.planosWebmotors = response.data.dados ?? []
      } catch (error) {
        this.notificacao('erro', 'Não foi possível consultar planos webmotors')
      }
    },
    async buscarPlanosMobiauto () {
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + localStorage.getItem('tokenBasica')
      let response = []
      try {
        response = await axios.get(process.env.API['automotor'] + '/mobiauto/planos/' + (this.modelPadrao.form.zero_km ? 1 : 0))
        this.planosMobiauto = response.data.dados ?? []
      } catch (error) {
        this.notificacao('erro', 'Não foi possível consultar planos mobiauto')
      }
    },
    async buscarPlanosOlx () {
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + localStorage.getItem('tokenBasica')
      let response = []
      try {
        response = await axios.get(process.env.API['automotor'] + '/olx/planos')
        this.planosOlx = response.data.dados ?? []
      } catch (error) {
        this.notificacao('erro', 'Não foi possível consultar planos olx')
      }
    },
    // Buscas
    async buscarPlacaRequest () {
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + localStorage.getItem('tokenBasica')
      this.buscandoPlaca = true
      let response = []
      try {
        const modelPlaca = new ConsultaPlacaIcarrosModel()
        response = await modelPlaca.getHashedId(this.modelPadrao.form.placa)
        // response = await axios.get(
        //   process.env.API['automotor'] +
        //   '/veiculo/consulta-icarros/' +
        //   this.modelPadrao.form.placa +
        //   '?v2=1'
        // )
        this.buscandoPlaca = false
      } catch (error) {
        this.notificacao('erro', 'Veículo não encontrado')
        this.buscandoPlaca = false
      }
      if (response) {
        return response.dados ? [response.dados] : null
      } else {
        this.notificacao(
          'erro',
          'Erro ao encontrar o veículo ! Tente novamente !'
        )
      }
    },
    async buscarPlaca (notificacao = true) {
      if (this.modelPadrao.form.placa !== '') {
        if (notificacao) {
          this.notificacao('enviando', 'Buscando Veículo, aguarde...')
        }
        try {
          let res = await this.buscarPlacaRequest()
          if (res.error) {
            res.error.map((erro) => {
              this.notificacao('erro', erro)
            })
            this.buscandoPlaca = false
          } else {
            if (notificacao) {
              this.notificacao(
                'salvo',
                'Veículo Encontrado ! Dados foram preenchidos !'
              )
            }
            if (Array.isArray(res) && res.length === 1) {
              this.placaResponse(res[0])
            } else {
              this.placaEncontradaVersoes = res
              this.qtdVersoesEncontradas = res.length
              this.modalveiculos = true
            }
          }
        } catch (error) {
          this.notificacao('erro', error)
        }
      } else {
        this.notificacao('aviso', 'Informe uma placa válida !')
      }
    },

    placaResponse (res) {
      console.log('placaResponse: ', res)
      if (res) {
        this.modalveiculos = false

        let camposResponse = clone(res)
        this.modelPadrao.form.codigo_molicar = camposResponse?.versao?.codigo_molicar ?? null
        camposResponse['situacao'] = camposResponse['situacao_bem']
        delete camposResponse['situacao_bem']

        let dados = res.dados_consulta
        dados['numero_motor_atual'] = dados['motor']
        dados['numero_motor_original'] = dados['motor']
        delete dados.motor

        let camposGenericos = Object.keys(camposResponse).filter(
          campo => campo !== 'dados_consulta'
        )
        camposGenericos.map(campo => {
          this.modelPadrao.form[`${campo}_id`] = camposResponse[campo].id
        })
        this.modelPadrao.form.cambio_id =
          dados['cambioAutomatico'] === 0 ? 2 : 1
        Object.keys(dados).map(campo => {
          switch (campo) {
            case 'marcaId':
              this.modelPadrao.form.marca_id = dados[`${campo}`]
              break
            case 'categoriaId':
              this.modelPadrao.form.carroceria_id = dados[`${campo}`]
              break
            case 'anoFabricacao':
              this.modelPadrao.form.ano_fabricacao = dados[`${campo}`]
              break
            case 'anoModelo':
              this.modelPadrao.form.ano_modelo = dados[`${campo}`]
              break
            case 'combustivelId':
              this.modelPadrao.form.combustivel_id = dados[`${campo}`]
              break
            case 'corId':
              this.modelPadrao.form.cor_id = dados[`${campo}`]
              break
            case 'nome':
              this.modelPadrao.form[`${campo}`] = dados[`${campo}`]
              break
            default:
              break
          }
        })
        setTimeout(() => {
          this.buscarPrecificadores('ano_modelo')
        }, 1250)
      }
    },

    buscarPrecificadores (precificador) {
      if (precificador === 'ano_modelo' && this.listas['versao_id'].length > 0 && this.modelPadrao.form.versao_id) {
        const model = new PrecificadorModel()
        this.listaPrecificadores['ano_modelo'].carregando = true

        let fipe = this.listas['versao_id'][this.listas['versao_id'].findIndex(ind => this.modelPadrao.form.versao_id === ind.id)]
        if (fipe !== undefined) {
          // this.modelCalcularPreco.form.valor_fipe = null
          fipe = fipe.codigo_fipe
          // search: `versao_id:${this.modelPadrao.form.versao_id};ano:${this.modelPadrao.form.ano_modelo}`,
          model
            .getListagem({
              params: {
                search: `codigo:${fipe};ano:${this.modelPadrao.form.ano_modelo}`,
                searchJoin: 'and',
                orderBy: 'id',
                sortedBy: 'desc',
                limit: 1
              }
            })
            .then(response => {
              let dados = response.dados ?? []
              this.listaPrecificadores[`${precificador}`].lista = this.listaPrecificadores[`${precificador}`].lista.concat(
                dados
              )
              if (dados.length > 0) {
                this.precificadorEncontrado = true
              }
              const atual = Object.assign([], this.listaPrecificadores)
              atual[`${precificador}`].carregando = !atual[`${precificador}`]
              this.listaPrecificadores = Object.assign([], atual)

              if (response.dados && response.dados[0] && response.dados[0].id) {
                this.modelPadrao.form.precificador_versao_id = response.dados[0].id
                this.modelPadrao.form.codigo_fipe = response.dados[0].codigo
              }
              this.$refs.field_ano_modelo.resetValidation()
              this.$refs.field_ano_modelo.validate()
            })
            .catch(() => {
              this.listaPrecificadores['ano_modelo'].carregando = false
              this.forceRender++
              this.listaPrecificadores['ano_fabricacao'].carregando = false
            })
        } else {
        }
      } else {
        this.listaPrecificadores['ano_fabricacao'].carregando = false
        this.listaPrecificadores['ano_fabricacao'].lista = []
      }
    },

    toggleModalPrecificadores (precificador) {
      let lista = Object.assign({}, this.listaPrecificadores)
      lista[`${precificador}`].modal = !lista[`${precificador}`].modal
      let resultado = Object.assign({}, lista)
      this.listaPrecificadores = resultado
    }
  }
}
