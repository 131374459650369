import { render, staticRenderFns } from "./VeiculoTabs.vue?vue&type=template&id=64088532&"
import script from "./VeiculoTabs.vue?vue&type=script&lang=js&"
export * from "./VeiculoTabs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QColor from 'quasar/src/components/color/QColor.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QTabs,QColor,QTab,QTabPanels});
