<template>
  <div class="row q-my-md">
    <div class="col-xs-12 col-md-2 col-lg-2 col-12 tabcustom" v-show="$q.screen.gt.sm">
      <div class="menu-lateral">

        <div :class="tab === 'dados' ? 'categoria ativa' : 'categoria'" @click="tab = 'dados', $emit('tab', { tab: 'dados' })">
          <p>Dados</p>
        </div>

        <div :class="tab === 'caracteristicas' ? 'categoria ativa' : 'categoria'" @click="tab = 'caracteristicas', $emit('tab', { tab: 'caracteristicas' })">
          <p>Características</p>
        </div>

        <div :class="tab === 'fotos' ? 'categoria ativa' : 'categoria'" @click="tab = 'fotos', $emit('tab', { tab: 'fotos' })">
          <p>Fotos Externas</p>
        </div>
        <div :class="tab === 'fotosInternas' ? 'categoria ativa' : 'categoria'" @click="tab = 'fotosInternas', $emit('tab', { tab: 'fotosInternas' })">
          <p>Fotos Internas</p>
        </div>
        <div :class="tab === 'fotosAvarias' ? 'categoria ativa' : 'categoria'" @click="tab = 'fotosAvarias', $emit('tab', { tab: 'fotosAvarias' })">
          <p>Fotos Avarias</p>
        </div>

        <div :class="tab === 'fotoInterna' ? 'categoria ativa' : 'categoria'" @click="tab = 'fotoInterna', $emit('tab', { tab: 'fotoInterna' })">
          <p>Foto Interna (360)</p>
        </div>

        <div :class="tab === 'laudos' ? 'categoria ativa' : 'categoria'" @click="tab = 'laudos', $emit('tab', { tab: 'laudos' })">
          <p>Laudos</p>
        </div>

        <div :class="tab === 'tipos-de-negociacao' ? 'categoria ativa' : 'categoria'" @click="tab = 'tipos-de-negociacao', $emit('tab', { tab: 'tipo-de-negociacao' })" v-show="externo">
          <p>Negociação</p>
        </div>

        <div :class="tab === 'anuncios' ? 'categoria ativa' : 'categoria'" @click="tab = 'anuncios'">
          <p>Anúncios</p>
        </div>

        <div :class="tab === 'ofertas' ? 'categoria ativa' : 'categoria'" @click="tab = 'ofertas'" v-show="false">
          <p>Ofertas</p>
        </div>

        <div :class="tab === 'vistorias' ? 'categoria ativa' : 'categoria'" @click="tab = 'vistorias'"  v-show="false">
          <p>Vistorias</p>
        </div>

        <div :class="tab === 'arquivos' ? 'categoria ativa' : 'categoria'" @click="tab = 'arquivos'"  v-show="false">
          <p>Fotos</p>
        </div>

        <div :class="tab === 'remocoes' ? 'categoria ativa' : 'categoria'" @click="tab = 'remocoes'" v-show="false">
          <p>Remoções</p>
        </div>

        <div :class="tab === 'financeiro' ? 'categoria ativa' : 'categoria'" @click="tab = 'financeiro'" v-show="false">
          <p>Financeiro</p>
        </div>

        <div :class="tab === 'estadias' ? 'categoria ativa' : 'categoria'" @click="tab = 'estadias'"  v-show="false">
          <p>Estadias</p>
        </div>

      </div>
    </div>

    <div class="col-xs-12 col-md-3 col-12 q-my-xl bg-white no-margin" v-show="$q.screen.lt.md">
      <q-tabs v-model="tab" inline-label align="left" style="color: var(--q-color-primary); width: 100%;" class="bg-white">
        <q-tab name="dados" @click="$emit('tab', { tab: 'dados' })" label="Dados" />
        <q-tab name="caracteristicas" label="Características" @click="$emit('tab', { tab: 'caracteristicas' })" />
        <q-tab name="fotos" label="Fotos / Vídeos" @click="$emit('tab', { tab: 'fotos' })" />
        <q-tab name="fotosInternas" label="Fotos Internas" @click="$emit('tab', { tab: 'fotosInternas' })" />
        <q-tab name="fotosAvarias" label="Fotos Avarias" @click="$emit('tab', { tab: 'fotosAvarias' })" />
        <q-tab name="fotoInterna" label="Foto Interna 360" @click="$emit('tab', { tab: 'fotoInterna' })" />
        <q-tab name="laudos" label="Laudos" @click="$emit('tab', { tab: 'laudos' })" />
        <q-tab name="tipos-de-negociacao" @click="$emit('tab', { tab: 'tipo-de-negociacao' })" label="Negociação" />
        <q-tab name="anuncios" label="Anúncios" @click="$emit('tab', { tab: 'anuncios' })" />
      </q-tabs>

      <div class="col-12">
        <hr />
      </div>

    </div>

    <div class="col-xs-12 col-md-10 col-lg-10 col-12 row painel-menu">
      <q-tab-panels style="width: 100%;" v-model="tab" animated>
        <slot></slot>
      </q-tab-panels>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      tab: 'dados'
    }
  },
  props: {
    externo: {
      type: Boolean,
      default: false
    }
  }
}
</script>
